@import "./scss/style.scss";

.follow {
    scroll-margin-top: 120rem;
    margin-top: 225rem;
    position: relative;
    width: 100%;

    @include tablet {
        margin-top: 150rem;
    }

    &__bg {
        & img {
            border-radius: $radius;
            @include tablet {
                width: 100%;
                height: 207rem;
            }
            @include tablet-small {
                height: 297rem;
            }
            @include mobile {
                height: 220rem;
            }
            @include mobile-small {
                height: 277rem;
            }
        }
        &::before {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            left: 0;
            background: rgba($color: #ffffff, $alpha: .3);
            bottom: 0;
            position: absolute;
            border-radius: $radius;
        }

        @include tablet {
            width: 100%;
            height: 207rem;
        }
        @include tablet-small {
            height: 297rem;
        }
        @include mobile {
            height: 220rem;
        }
        @include mobile-small {
            height: 277rem;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        padding: 92rem 175rem 99rem 20rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 250rem;
        @include tablet {
            padding: 54rem 77rem 44rem 20px;
            gap: 109rem;
            width: 100%;
        }
        @include tablet-small {
            padding: 20px;
            gap: 20rem;
            width: 100%;
        }
        @include mobile {
            padding: 10px;
        }
    }

    &__info {
        @include flex-col;
        gap: 30rem;
        width: 622rem;
        @include tablet {
            gap: 20rem;
        }
        & h3 {
            @include title($ff, 500, 40rem, 48rem, $black);
            margin-top: 16rem;
            @include tablet {
                margin-top: 20rem;
                @include title($ff, 500, 25px, 30px, $black);
            }
            @include mobile {
                margin-top: 0;
                @include title($ff, 500, 18px, 24px, $black);
            }
        }
        & p {
            @include desc($ff2, 20rem, 26rem, $black);
            max-width: 599rem;
            @include tablet {
                @include desc($ff2, 18px, 24px, $black);
            }
            @include mobile {
                @include desc($ff2, 14px, 20px, $black);
            }
        }
    }

    &__link {
        @include flex-col;
        gap: 26rem;
        @include tablet {
            gap: 20rem;
        }
        & img {
            width: 134rem;
            height: 134rem;
            @include tablet {
                width: 108px;
                height: 108px;
            }
            @include tablet-small {
                width: 80px;
                height: 80px;
            }
            @include mobile {
                width: 40px;
                height: 40px;
            }
            @include mobile-small {
                width: 30px;
                height: 30px;
            }
        }
        & h4 {
            @include desc($ff2, 40rem, 46rem, $black);
            text-decoration: underline;
            @include tablet {
                @include desc($ff2, 35rem, 40rem, $black);
            }
            @include mobile {
                @include desc($ff2, 16px, 20px, $black);
            }
        }
    }
}