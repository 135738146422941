@import "./scss/style.scss";

.main {
  margin-bottom: 200rem;
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -2;
    width: 100%;
    height: 900rem;
  }

  &__container {
    @include glass-bg-main;
    padding: 44rem 20rem;
    margin-top: 297rem;
    @include flex-col;
    & h1 {
      @include title($ff, 400, 45rem, 55rem, $black);
      width: 100%;
    }
    & p {
      margin-top: 15rem;
      @include desc($ff2, 20rem, 24rem, $black);
      max-width: 435rem;
      margin-bottom: 10rem;
      & span {
        @include desc($ff3, 20rem, 26rem, $black);
        letter-spacing: 0.3px;
      }
    }
  }

  &__desc {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include mobile-small {
      flex-direction: column-reverse;
    }
    @include mobile {
      flex-direction: column;
    }

    & button {
      margin-top: 20rem;
      max-width: 290rem;
      @include mobile {
        width: 200px;
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        margin-top: 22rem;
      }
    }
    & p {
      margin-top: 52rem;
      @include desc($ff2, 20rem, 24rem, $black);
      max-width: 470rem;
      & span {
        @include desc($ff3, 20rem, 24rem, $black);
      }
      @include mobile {
        max-width: 198px;
        margin-top: 22rem;
        position: relative;
        right: 0;
      }
      @include mobile-small {
        margin-top: 20rem;
      }
    }
  }
}
