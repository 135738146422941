@import "./scss/style.scss";

.footer {
  @include flex-col;
  gap: 52rem;
  width: 100%;

  &__links.desktop {
    @include flex-between;
    width: 100%;
  }
  &__links.mobile {
    @include flex-between;
    gap: 20rem;
    width: 100%;
    @include mobile {
      flex-direction: column-reverse;
    }
  }
  &__links-bottom {
    @include flex-between;
  }

  &__location.desktop {
    display: flex;
    gap: 32rem;
  }
  &__location.mobile {
    @include flex-col;
    gap: 30rem;
  }

  &__map {
    width: 330px;
    height: 318px;
    cursor: pointer;
  }

  &__contacts.desktop {
    @include flex-col;
    gap: 30rem;
  }
  &__contacts.mobile {
    @include flex-col;
    gap: 30rem;
  }
  &__contacts-tablet {
    @include flex-col;
    gap: 30rem;
  }

  &__phone {
    @include desc($ff2, 25rem, 30rem, $black);
  }

  &__address {
    display: flex;
    align-items: center;
    gap: 4rem;
    & img {
      width: 20rem;
      height: auto;
    }
    & a {
      @include desc($ff2, 23rem, 25rem, $black);
    }
  }

  &__time {
    @include flex-col;
    text-align: center;
    & p {
      @include desc($ff2, 23rem, 28rem, $black);
    }
  }

  &__logo img {
    width: 326rem;
    height: 326rem;
  }

  &__bottom {
    background: $black;
    padding: 30rem 0;
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 70rem;
    @include tablet {
      gap: 30rem;
    }
    & img {
      width: 28rem;
      height: auto;
    }
  }

  &__document a {
    @include desc($ff2, 20rem, 24rem, $white);
    text-decoration: underline;
  }
}
