@mixin title($ff, $fw, $fs, $lh, $color) {
    font-family: $ff;
    font-weight: $fw;
    font-size: $fs;
    line-height: $lh;
    color: $color;
}
@mixin desc($ff, $fs, $lh, $color) {
    font-family: $ff;
    font-size: $fs;
    line-height: $lh;
    color: $color;
}

@mixin desktop-small {
    @media (max-width: $desktop-small) {
        @content;
    }
}
  
@mixin tablet-small {
    @media (max-width: $tablet-small) {
        @content;
    }
}
  
@mixin tablet {
    @media (max-width: $tablet) {
        @content;
    }
}
  
@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}
@mixin mobile-small {
    @media (max-width: $mobile-small) {
        @content;
    }
}

@mixin flex-center { display: flex; justify-content: center; align-items: center; }
@mixin flex-col { display: flex; flex-direction: column; }
@mixin flex-between { display: flex; justify-content: space-between; align-items: center; }

@mixin glass-bg-header {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
}
@mixin glass-bg-main {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
}