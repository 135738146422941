@import "./scss/style.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  line-height: inherit;
}

html {
  font-size: 1px;
  margin-inline: auto;
  scroll-behavior: smooth;
  @include desktop-small {
    font-size: 0.9px;
  }
  @include tablet {
    font-size: 0.8px;
  }
  @include tablet-small {
    font-size: 0.7px;
  }
  // @include mobile {
  //   font-size: 0.6px;
  // }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
body {
  font-family: $ff2;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 10rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $primary;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: $beige;
}
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: $beige;
}

code {
  font-family: $ff;
}
a {
  text-decoration: none;
}
ul,
li {
  text-decoration: none;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
}
button {
  background-color: transparent;
}
*::-webkit-scrollbar {
  width: 7rem;
  background-color: #ffffff00;
}
*::-webkit-scrollbar-thumb {
  border-radius: 7rem;
  background-color: $black;
}
::-moz-selection {
  color: $primary;
  background: $black;
}
::selection {
  color: $primary;
  background: $black;
}