@import "./scss/style.scss";

.contract {
    margin-top: 236rem;
    @include flex-col;
    gap: 58rem;
    margin-bottom: 200rem;
    & h3 {
        @include title($ff, 400, 79rem, 90rem, $black);
    }
    &>p {
        @include desc($ff2, 35rem, 45rem, $black);
        margin-bottom: 204rem;
        @include tablet {
            margin-bottom: 100rem;
        }
    }

    &__bloc {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(620rem, 1fr));
        gap: 40rem;
        @include tablet {
            gap: 0;
            @include flex-col;
            width: 100%;
        }
    }

    &__side {
        background: $beige;
        padding: 40rem;
        @include flex-col;
        gap: 20rem;
        &:nth-child(2n) {
            @include tablet {
                padding-top: 0;
            }
        }
        & p {
            @include desc($ff2, 20rem, 24rem, $black);
        }
        & ul {
            @include flex-col;
            gap: 20rem;
        }
        & ul li {
            @include desc($ff2, 20rem, 24rem, $black);
            list-style-type: disc;

        }
    }
}