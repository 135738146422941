@font-face {
	font-family: 'Playfair Display';
	src: url('../../../public/fonts/PlayfairDisplay-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Playfair Display';
	src: url('../../../public/fonts/PlayfairDisplay-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}
@font-face {
    font-family: 'Effra Trial Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Trial Regular'), url('../../../public/fonts/Effra_Trial_Rg.woff') format('woff');
}
@font-face {
    font-family: 'Effra Trial Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Trial Medium'), url('../../../public/fonts/Effra_Trial_Md.woff') format('woff');
}

$ff: "Playfair Display", system-ui, serif;
$ff2: "Effra Trial Regular", system-ui, sans-serif;
$ff3: "Effra Trial Medium", system-ui, sans-serif;

$black: #000000;
$beige: #F8F1EB;
$white: #FFFFFF;
$primary: #F4DED3;
$secondary: #B49980;

$radiusSmall: 3px;
$radius: 5px;

$transition: .3s all ease-in-out;

$transY: translateY(10rem);

$desktop-small: 1280px;
$tablet: 992px;
$tablet-small: 768px;
$mobile: 576px;
$mobile-small: 380px;