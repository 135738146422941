@import "./scss/style.scss";

.wrapper {
  max-width: 1280rem;
  height: auto;
  margin-inline: auto;
  @include desktop-small {
    max-width: 1200rem;
  }
  @include tablet {
    max-width: 872px;
  }
  @include tablet-small {
    max-width: 648px;
  }
  @include mobile {
    max-width: 496px;
  }
  @include mobile-small {
    max-width: 330px;
  }
}
.additional {
  padding: 0 20px;
  @include mobile {
    padding: 0 10px;
  }
}

.btn {
  display: inline-block;
  cursor: pointer;
  z-index: 100;
  background-color: $primary;
  padding: 15rem 20rem;
  @include desc($ff2, 18rem, 20rem, $black);
  border-radius: $radiusSmall;
  &:hover {
    transition: $transition;
    transform: scale(1.05);
    background-color: $beige;
    border: 1px solid $secondary;
    color: $black;
    &::-moz-selection {
      color: $black;
      background-color: $beige;
    }
    &::selection {
      color: $black;
      background-color: $beige;
    }
  }
  &:active {
    transform: translateY(8px);
  }
  &::-moz-selection {
    color: $black;
    background-color: $primary;
  }
  &::selection {
    color: $black;
    background-color: $primary;
  }
  @include tablet {
      @include desc($ff2, 13px, 18px, $black);
  }
  @include mobile {
      @include desc($ff2, 10px, 14px, $black);
  }
}