@import "./scss/style.scss";

.service {
  scroll-margin-top: 120rem;

  &__category {
    @include flex-col;
    gap: 20rem;
    & > h2 {
      margin-top: 40rem;
      @include desc($ff2, 40rem, 48rem, $black);
    }
  }

  &__list {
    @include flex-col;
    gap: 20rem;
  }

  &__header {
    @include flex-between;
    padding: 21rem 20rem;
    border: 1px solid $secondary;
    border-radius: $radiusSmall;
    background: $beige;
    & > h3 {
      @include desc($ff2, 26rem, 34rem, $black);
    }
  }
  &__more {
    @include flex-col;
    width: 100%;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30rem 20rem 35rem;
    border: 1px solid $secondary;
    border-radius: 0 0 $radiusSmall $radiusSmall;
    background: $beige;
    gap: 45rem;
    border-top-color: none;
    position: relative;
    &::before {
      content: "";
      display: block;
      height: 1px;
      left: 0;
      background: $black;
      top: -2rem;
      position: absolute;
      width: 100%;
    }
    @include tablet {
      flex-direction: column-reverse;
      width: 100%;
    }
  }

  &__left {
    max-width: 594rem;
    @include flex-col;
    justify-content: space-between;
    gap: 30rem;
    & p {
      @include desc($ff2, 20rem, 26rem, $black);
    }
    @include tablet {
      max-width: 100%;
    }
  }

  &__table {
    width: 100%;
    @include desc($ff2, 20rem, 26rem, $black);
    & tr:hover {
      transition: $transition;
      background: $primary;
    }
    & td {
      padding: 10rem;
      &:nth-child(2) {
        text-align: end;
        width: 120px;
        @include mobile {
          width: 80px;
        }
      }
    }
  }

  &__right {
    width: 100%;

    & img {
      width: 600rem;
      height: auto;
      @include tablet {
        width: 100%;
        height: 277px;
      }
    }
  }
  &__additional {
    @include flex-col;
    padding: 25rem 20rem 40rem;
    border: 1px solid $secondary;
    border-radius: 0 0 $radiusSmall $radiusSmall;
    background: $beige;
    gap: 55rem;
    border-top-color: $black;
    & h4 {
      @include desc($ff2, 26rem, 34rem, $black);
    }

    position: relative;
    &::before {
      content: "";
      display: block;
      height: 1px;
      left: 0;
      background: $black;
      bottom: 210rem;
      position: absolute;
      width: 100%;
    }
  }
}
.chevron.open {
  transform: rotate(180deg);
}
