@import "./scss/style.scss";

.header {
  @include glass-bg-header;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;

  .header_modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    z-index: 3000;
    .header_modal_overlay {
      width: 100vw;
      height: 100vh;
      background-color: black;
      opacity: 0.4;
      position: fixed;
      backdrop-filter: blur(10px);
      z-index: 3000;
    }
    iframe {
      height: 100vh;
      position: absolute;
      z-index: 4000;
      right: 0;
    }
  }

  &__container {
    @include flex-between;
    padding: 27rem 0;
    position: relative;
    width: 100%;
    z-index: 20;
  }

  &__logo img {
    width: 204rem;
    height: auto;
    @include mobile {
      width: 164rem;
    }
  }

  &__nav {
  }

  &__ul {
    @include flex-center;
    gap: 30rem;
  }

  &__li {
    @include desc($ff2, 14px, 18px, $black);
  }

  &__controls {
    display: flex;
    align-items: center;

    button {
      @include mobile {
        font-size: 14px;
      }
      @include mobile-small {
        display: none;
      }
    }
  }

  &__burger {
    display: none;
    cursor: pointer;

    & span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: #000;
      margin: 5px 0;
      transition: all 0.3s ease;
    }

    &.open span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
    &.open span:nth-child(2) {
      opacity: 0;
    }
    &.open span:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
    &.open {
      span {
        background-color: $black;

        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          margin-top: -16px;
        }
      }
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px); // Блюр фону чомусь не працює
    -webkit-backdrop-filter: blur(20px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }
  }
}

@media (max-width: 992px) {
  .header__nav {
    position: absolute;
    top: 0;
    right: -130px;
    width: 100%;
    height: 100vh;
    background: $primary;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    display: none;

    &.active {
      display: block;
      transform: translateY(0);
    }

    .header__ul {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
      margin-top: 40rem;

      li {
        margin: 10px 0;
      }
    }
  }

  .header__controls {
    .btn {
      order: 1;
    }

    .header__burger {
      display: block;
      order: 2;
      margin-left: 20px;
    }
  }
}
