@import "./scss/style.scss";

.gallery {
    scroll-margin-top: 120rem;
    margin-top: 200rem;
    position: relative;
    margin-bottom: 144rem;

    &__swiper {
        width: 100%;
    }

    &__slide {
        padding-bottom: 60rem;
    }
    &__btns {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 40%;
        z-index: 2;
        width: 100%;
        & button {
            cursor: pointer;
            &:nth-child(1) {
                margin-left: 20rem;
            }
            &:nth-child(2) {
                margin-right: 20rem;
            }
        }
    }
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 630rem !important;
    position: absolute;
}

.swiper-pagination-horizontal.swiper-pagination-bullets 
.swiper-pagination-bullet {
    width: 20rem;
    height: 20rem;
    background: $white;
    opacity: 1;
    margin: 0 7.5rem !important;
    &-active {
        border: 1px solid $secondary;
        background: $beige;

    }
}
