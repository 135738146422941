@import "./scss/style.scss";
.additional_modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  z-index: 3000;
  .additional_modal_overlay {
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.4;
    position: fixed;
    backdrop-filter: blur(10px);
    z-index: 3000;
  }
  iframe {
    height: 100vh;
    position: absolute;
    z-index: 4000;
    right: 0;
  }
}

.about {
  scroll-margin-top: 120rem;
  margin-top: 393rem;
  background: $beige;
  border-radius: $radiusSmall;
  padding: 50rem 40rem;
  display: flex;
  gap: 30px;
  margin-bottom: 160rem;
  height: auto;

  @include tablet {
    flex-direction: column-reverse;
    padding: 20px;
    // max-width: 740px;
  }

  &__info {
    @include flex-col;
    gap: 20rem;
    max-width: 589rem;
    @include tablet {
      gap: 10px;
      max-width: 100%;
    }
    & h2 {
      @include title($ff, 500, 40rem, 52rem, $black);
      margin-bottom: 15rem;
      @include tablet {
        margin-bottom: 10px;
        @include title($ff, 500, 25px, 30px, $black);
        width: 435px;
      }
      @include mobile {
        width: 100%;
      }
    }
    & p {
      @include desc($ff2, 20rem, 23rem, $black);
      max-width: 575rem;
      @include tablet {
        @include desc($ff2, 18px, 23px, $black);
        max-width: 575px;
      }
      & span {
        @include desc($ff3, 20rem, 24rem, $black);
      }
    }
    & button {
      max-width: 290rem;
      margin-top: 25rem;
      z-index: 3;
      padding: 17rem;
      @include tablet {
        margin-top: 10px;
      }
      @include mobile {
        font-size: 14px;
      }
    }
  }

  &__pic {
    width: 581px;
    height: auto;
    @include tablet {
      width: 100%;
    }
    & img {
      border-radius: $radius;
      @include tablet {
        width: 100%;
        height: 336px;
      }
    }
  }
}
